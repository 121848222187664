<template>
  <transition
    name="slide"
    v-bind="$attrs"
    @after-enter="afterEnter"
    @enter="enter"
    @leave="leave">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: "SlideTransition",
  props: {
    duration: {
      type: Number,
      default: 350,
      required: false
    }
  },
  methods: {
    enter(element) {
      const { width } = getComputedStyle(element);

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";
      element.style.transitionDuration = `${this.duration / 1000}s`;

      const { height } = getComputedStyle(element);

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = "auto";
    },

    leave(element) {
      const { height } = getComputedStyle(element);

      element.style.height = height;
      element.style.transitionDuration = `${this.duration / 1000}s`;

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
* {
  will-change: height;
  backface-visibility: hidden;
}

.slide-enter-active,
.slide-leave-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-property: opacity, height;
  overflow: hidden;
}

.slide-enter-from,
.slide-leave-to {
  height: 0;
  opacity: 0;
}
</style>
